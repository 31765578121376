<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom mb-2" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 141px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            rutas: [
                { titulo: 'Equipos', ruta: 'admin.equipos.listar' },
                { titulo: 'Comparación de equipos', ruta: 'admin.equipos.comparacion-equipos' }
            ]
        }
    }
}
</script>
